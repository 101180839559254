import * as React from 'react';
import axios from 'axios';
import {
    useParams,
  } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box,CardHeader} from '@mui/material';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from 'moment/moment';
import './match.css';
import {appConstants} from "../_constants/app.constans";

const MatchTable = ({match,index}) => {
  const styleLocal = {};
  const styleForeign = {};
  if (match.completed) {
    if (match.localGoals > match.foreignGoals) {
      styleLocal.color = 'green'; // Si el equipo local ganó, el resultado se muestra en verde
      styleLocal.textDecoration = 'underline';
      styleForeign.color = 'red'; // Si el equipo local ganó, el resultado extranjero se muestra en rojo
    } else if (match.localGoals < match.foreignGoals) {
      styleLocal.color = 'red'; // Si el equipo local perdió, el resultado se muestra en rojo
      styleForeign.textDecoration = 'underline';
      styleForeign.color = 'green'; // Si el equipo local perdió, el resultado extranjero se muestra en verde
    } else {
      styleLocal.color = 'orange'; // Si hubo un empate, el resultado se muestra en naranja
      styleForeign.color = 'orange'; // Si hubo un empate, el resultado extranjero se muestra en naranja
    }
  }
  return (
      <tr key={index}>
        <td><span className={match.isActive ? 'matchTime blink' : 'matchTime'}>{moment(match.dayHour).format("HH:mm")}</span></td>
        <td><span className="shieldImg"><img className="logo" src={match.localTeam.shield} /></span></td>
        <td><span style={styleLocal}>{match.localTeam.name}</span></td>
        <td style={{minWidth:"60px"}} ><span className="matchResult"><span style={styleLocal}  className={match.isActive ? 'blink' : ''}>{match.localGoals ?? 0}</span> - <span style={styleForeign}  className={match.isActive ? 'blink' : ''}>{match.foreignGoals ?? 0}</span></span></td>
        <td><span style={styleForeign}>{match.foreignTeam.name}</span></td>
        <td><span className="shieldImg"><img className="logo" src={match.foreignTeam.shield} /></span></td>
      </tr>
    );
}

export default function Matches (){
    const [matches, setMatches] = useState([]);
    const [fields, setFields] = useState([]);
    const {tournamentId} = useParams();
    const [tId, setTournamentId] = useState(tournamentId);

    useEffect(() => {
      if (!tId) {
          let today = moment().format('YYYY-MM-DD');
          axios.get(`${appConstants.API_URL}/api/tournaments`)
              .then((response) => {
                  let tournaments = response.data;
                  let tournament = tournaments.find(tournament => moment(tournament.day).format('YYYY-MM-DD') == today);
                  if(tournament !== undefined) {
                      setTournamentId(tournament.id);
                  }
              });
      }
    }, []);

    const fetchData = async (data) => {
      try {
        const response = await axios.get(
          `${appConstants.API_URL}/api/tournaments/TournamentImages/${tId}`
        );
        localStorage.setItem(tId + "-images", JSON.stringify(response.data));
        updateShields(data);
      } catch (error) {
        console.error(error);
      }
    };

    const updateShields = (dataFetched) => {
      const sessionData = localStorage.getItem(tId + "-images");
      const data = JSON.parse(sessionData);
      const matchesData = dataFetched;
      console.log(matchesData);
      matchesData.forEach(match => {
        match.localTeam.shield = data.find(x => x.id === match.localTeam.id).imageData;
        match.foreignTeam.shield = data.find(x => x.id === match.foreignTeam.id).imageData;
      });
      setMatches(matchesData);
      setFields([...new Set(matchesData.map(match => match.fieldName))]);
    }

    useEffect(() => {
        const fetchMatches = async () => {
        try {
            const res = await axios.get(`${appConstants.API_URL}/api/tournamentmatches/${tId}`);
            const sessionData = localStorage.getItem(tId + "-images");
            if (sessionData) {
              updateShields(res.data);
            } else {
              fetchData(res.data);
            }
        } catch (err) {
          console.error(err);
        }
        };
        fetchMatches();

        const interval = setInterval(fetchMatches, 10000);

        return () => clearInterval(interval);
        }, [tId]);
        // Establece un intervalo para actualizar los datos cada 5 segundos
    


  return (
    <React.Fragment>
        <Box
        justifyContent="center"
        alignItems="center"
        >
      <Grid container spacing={3}>
      {fields && fields.sort().map((field) => {
        return (
          <Grid item xs={12} sm={fields.length>2 ? 3:6}>
          <Card variant="outlined">
            <CardHeader className='lightColorGreen' title={field} style={{ textAlign: 'center' }}/> 
            <CardContent>
            <div className="matchTeams">
            <table>
              <tbody>
                      {matches.map((match,index) => {
                        if (match.fieldName === field) {
                          return (
                            <MatchTable match={match} index={index}/>
                          );
                        }
                      })}
                      </tbody>
            </table>
          </div>
            </CardContent>
          </Card>
        </Grid>);})}
      </Grid>
      </Box>
    </React.Fragment>
  );
};