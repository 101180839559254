import * as React from 'react';
import {
    useParams,
  } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Bracket,BracketGenerator,Game } from 'react-tournament-bracket';
import {appConstants} from "../_constants/app.constans";
import './TournamentBracket.css';
import moment from 'moment/moment';

export default function ViewBracket (trId){
    const [brackets, setBracketMatchs] = useState([]);
    const {tournamentId} = useParams();
    const [tId, setTournamentId] = useState(tournamentId);

    useEffect(() => {
        if (!tId) {
            let today = moment().format('YYYY-MM-DD');
            axios.get(`${appConstants.API_URL}/api/tournaments`)
                .then((response) => {
                    let tournaments = response.data;
                    let tournament = tournaments.find(tournament => moment(tournament.day).format('YYYY-MM-DD') == today);
                    if(tournament !== undefined) {
                        setTournamentId(tournament.id);
                    }
                });
        }
      }, []);
    
    useEffect(() => {
        const fetchBracketMatchs2 = async () => {
        try {
            const res = await axios.get(`${appConstants.API_URL}/api/tournamentmatches/${tId ?? trId}/bracketmatchs/component2`);
            setBracketMatchs(res.data);
            console.log(res.data)
        } catch (err) {
            console.error(err);
        }
        };
        fetchBracketMatchs2();

        const interval = setInterval(fetchBracketMatchs2, 20000);

        return () => clearInterval(interval);
    }, [tId]);

    return (<>
            {brackets.length > 0 &&
                brackets.map((bracket) => {
                    return(
                    <Bracket   topText={({ scheduled }) => new Date(scheduled).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} game={bracket} />
                    );
                })
            }
         </>
      );
};