import * as React from 'react';
import axios from 'axios';
import {
    useParams,
  } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { Box,CardHeader} from '@mui/material';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from 'moment/moment';
import './groups.css';
import {appConstants} from "../_constants/app.constans";

const GroupTable = ({groupTeam,index}) => {
    console.log(groupTeam);
  return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><span className="shieldImg"><img className="logo" src={groupTeam.team.shield} /></span></td>
        <td><span>{groupTeam.team.name}</span></td>
        <td><span>{groupTeam.points ?? 0}</span></td>
        <td><span>{groupTeam.goals ?? 0}</span></td>
        <td><span>{groupTeam.goalsReceived ?? 0}</span></td>
        <td><span>{groupTeam.matchsPlayed ?? 0}</span></td>
      </tr>
    );
}

export default function ViewGroups (){
    const [groups, setGroups] = useState([]);
    const {tournamentId} = useParams();
    const [tId, setTournamentId] = useState(tournamentId);

    useEffect(() => {
      if (!tId) {
          let today = moment().format('YYYY-MM-DD');
          axios.get(`${appConstants.API_URL}/api/tournaments`)
              .then((response) => {
                  let tournaments = response.data;
                  let tournament = tournaments.find(tournament => moment(tournament.day).format('YYYY-MM-DD') == today);
                  if(tournament !== undefined) {
                      setTournamentId(tournament.id);
                  }
              });
      }
  }, []);

    const fetchData = async (data) => {
      try {
        const response = await axios.get(
          `${appConstants.API_URL}/api/tournaments/TournamentImages/${tId}`
        );
        localStorage.setItem(tId + "-images", JSON.stringify(response.data));
        updateShields(data);
      } catch (error) {
        console.error(error);
      }
    };

    const updateShields = (dataFetched) => {
      const sessionData = localStorage.getItem(tId + "-images");
      const data = JSON.parse(sessionData);
      const groupsData = dataFetched;
      console.log(groupsData);
      groupsData.forEach(group => {
        group.tournamentGroupTeams.forEach(team => {
          team.team.shield = data.find(x => x.id === team.team.id).imageData;
        });
      });
      setGroups(groupsData);
    }

    useEffect(() => {

        const fetchGroups = async () => {
        try {
            const res = await axios.get(`${appConstants.API_URL}/api/tournamentgroups/${tId}/teams/ordered`);
            const sessionData = localStorage.getItem(tId + "-images");
            if (sessionData) {
              updateShields(res.data);
            } else {
              fetchData(res.data);
            }
        } catch (err) {
          console.error(err);
        }
        };
        fetchGroups();
        const interval = setInterval(fetchGroups, 10000);

        return () => clearInterval(interval);
        }, [tId]);


  return (
    <React.Fragment>
        <Box
        justifyContent="center"
        alignItems="center"
        >
      <Grid container spacing={3}>
      {groups.map((group,indexf) => {
        return(
        <Grid item xs={12} sm={groups.length > 4 ? 3 : 6}>
          <Card variant="outlined">
            <CardHeader className='lightColorGreen' title={group.name} style={{ textAlign: 'center' }}/> 
            <CardContent>
            <div className="matchTeams">
                <table>
                    <thead>
                      <th>#</th>
                      <th></th>
                      <th>Equipo</th>
                      <th>Puntos</th>
                      <th>G. F.</th>
                      <th>G. E.</th>
                      <th>PJ</th>
                    </thead>
                    <tbody>
                      {group.tournamentGroupTeams.map((team,index) => {
                          return (
                            <GroupTable groupTeam={team} index={index}/>
                          );
                      })}
                    </tbody>
                </table>
            </div>
            </CardContent>
          </Card>
        </Grid>);
        })}
      </Grid>
      </Box>
    </React.Fragment>
  );
};